export const environment = {
	production: true,
	useEmulators: false,
	firebase: {
		apiKey: 'AIzaSyBbW_k87qD4tY2JbbC0yI8SeSfg5e-TAA8',
		authDomain: 'faith.dev.amiti.online',
		databaseURL: 'https://amiti-plex-dev.firebaseio.com',
		projectId: 'amiti-plex-dev',
		storageBucket: 'amiti-plex-dev.appspot.com',
		messagingSenderId: '22164672395',
	},
	product: 'faith',
	admin: 'https://faith.dev.amiti.online',
	client: 'dev.amiti.online',
	olympus: 'olympus.dev.amiti.online',
	googleMapAPI: 'AIzaSyAzWqVR1RPGxyelPuI55AZsT129xBS4UCk',
	port: 8005,
	allowedLoginDomains: ['noldor.co.za', 'noltech.co.za'],
	useSentry: false,
	region: 'us-central1',
};
